@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0 ;
  
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* body{
background-color: rgb(1, 2, 2);
position: relative;
z-index: 0;  
} */

html, body {
  width: 100%;
  height:100%;
}

body {
    background: rgb(227, 230, 230);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

